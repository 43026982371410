import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Paper, Snackbar, Stack, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tab, TabPanel } from '../../../components/Tabs';
import ErrorState from '../../../components/alerts/ErrorState';
import { Loading } from '../../../components/styled';
import { useGetProject, usePutProject } from '../../../queries';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { projectCraneActions } from '../../../store/projectcrane';
import { toBase64 } from '../../../utils/helpers';
import { SiteUsers, SiteCranes, SiteParameters } from './components';

const Site = () => {
  const { companyId, siteId: siteIdParams } = useParams();
  const Project = useAppSelector((state: any) => state.projectCrane.Project);

  const [siteId, setSiteId] = React.useState<string | null>(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    const newSiteId = siteIdParams || Project;
    setSiteId(newSiteId);
  }, [siteIdParams, Project]);

  // useEffect(() => {
  //   console.log('siteId', siteId);
  //   if (siteId) refetchSite();
  // }, [siteId]);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    error: getSiteError,
    isFetching: getSiteIsFetching,
    data: siteData,
    refetch: refetchSite,
  } = useGetProject(
    { siteId },
    {
      enabled: !!siteId,
      cacheTime: 0,
    },
  );

  const {
    mutate: siteMutate,
    isLoading: putProjectIsLoading,
    error: putProjectError,
  } = usePutProject({
    onSuccess: () => {
      refetchSite();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutProject');
    },
  });

  useEffect(() => {
    if (siteData?.data && siteData?.data.prohibitedZones) {
      dispatch(projectCraneActions.setZones(siteData?.data.prohibitedZones));
    }
  }, [siteData]);

  const onChangeImageData = async (file: File) => {
    const imageBase64: any = await toBase64(file);

    if (imageBase64) {
      const imageEncoded = JSON.stringify(imageBase64);
      siteMutate({ siteId, imageEncoded });
    }
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const snackbarError = (putProjectError as Error)?.message;
  const pageError = getSiteError;
  const pageIsLoading = getSiteIsFetching || putProjectIsLoading;

  if (pageIsLoading) {
    return <Loading />;
  }

  if (
    pageError ||
    // || !companyId
    !siteId
  ) {
    return (
      <Paper
        sx={{
          padding: 8,
          backgroundColor: 'common.white',
          width: '100%',
          overflowY: 'auto',
          display: 'flex',
          flex: 1,
        }}
        elevation={1}
      >
        <ErrorState error={pageError} />
      </Paper>
    );
  }

  return (
    <Stack height="100%" flex={1} spacing={4}>
      <Tabs value={selectedTab} onChange={onTabChange}>
        <Tab label="Site" />
        <Tab label="Cranes" />
        {/* <Tab label="Anti-collision" /> */}
        {/* <Tab label="Work hours" /> */}
        <Tab label="Users" />
        {/* <Tab label="Settings" /> */}
      </Tabs>
      <Grid container rowSpacing={4} flex={1}>
        {siteData && (
          <TabPanel value={selectedTab} index={0}>
            <SiteParameters
              {...{
                siteData: siteData?.data,
                onChangeImageData,
                siteMutate,
              }}
            />
          </TabPanel>
        )}
        <TabPanel value={selectedTab} index={1}>
          <SiteCranes
            siteId={siteId}
            siteCranes={siteData?.data.cranes}
            refetchSite={refetchSite}
            getSiteIsFetching={getSiteIsFetching}
            getSiteError={getSiteError}
          />
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={2}>
          <ProjectWorkHours />
        </TabPanel> */}
        <TabPanel value={selectedTab} index={2}>
          <SiteUsers
            siteId={siteId}
            siteUsers={siteData?.data.users}
            refetchSite={refetchSite}
          />
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={4}>
          <ProjectSettings
            {...{
              projectData: siteData?.data,
              siteMutate,
            }}
          />
        </TabPanel> */}
      </Grid>
      <Snackbar
        open={openSnackbar && !!snackbarError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarError}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};

export default Site;
