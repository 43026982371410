import React, { useEffect, useState } from 'react';
import { Grid } from '../../components/grid';
import ErrorState from '../../components/alerts/ErrorState';
import {
  ContentStack,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import getColumnsDef from './columns';
import {
  useGetUsers,
  useDeleteUser,
  usePutInviteUser,
  usePutUserActivationStatus,
  usePutUserReceiveReportsStatus,
} from '../../queries';
import UpsertUser from './UpsertUser';
import { Toolbar } from './Toolbar';
import { ConfirmDialog } from '../../components/confirm';
import user from '../../store/user';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const UserList = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  const {
    data: usersData,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetUsers(
    {},
    {
      onSuccess: (data: any) => {
        setUsers(data.data);
        setIsLoadingUsers(false);
      },
      onError: (error: any) => {
        setIsLoadingUsers(false);
      },
    },
  );

  const {
    mutateAsync: deleteUser,
    error: deleteError,
    data: deleteData,
    isLoading: deleteLoading,
    reset: resetDelete,
  } = useDeleteUser();

  const {
    mutateAsync: updateUserActivationStatus,
    error: updateUserActivationStatusError,
    data: updateUserActivationStatusData,
    isLoading: updateUserActivationStatusLoading,
  } = usePutUserActivationStatus();

  const {
    mutateAsync: updateUserReceiveReportsStatus,
    error: updateUserReceiveReportsStatusError,
    data: updateUserReceiveReportsStatusData,
    isLoading: updateUserReceiveReportsStatusLoading,
  } = usePutUserReceiveReportsStatus();

  const {
    mutateAsync: inviteUser,
    error: inviteError,
    data: inviteData,
    isLoading: inviteLoading,
    reset: resetInvite,
  } = usePutInviteUser();

  const [editItemId, setEditItemId] = useState<number | undefined>();
  const [deleteItemId, setDeleteItemId] = useState<number | undefined>();
  const [inviteItemId, setInviteItemId] = useState<number | undefined>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({});

  const filteredUsers = users.filter((user) => {
    const query = filters.query?.toLowerCase() || '';
    return (
      user.firstName.toLowerCase().includes(query) ||
      user.lastName.toLowerCase().includes(query) ||
      user.email.toLowerCase().includes(query) ||
      user.company.name.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    (async () => {
      setIsLoadingUsers(true);
      await refetch();
    })();
  }, [refetch]);

  const onEdit = (editItemId?: number) => {
    setEditItemId(editItemId);
  };

  const onCreate = (open: boolean) => {
    setDialogOpen(open);
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = async () => {
    await deleteUser({ ID: deleteItemId });
  };

  const onInvite = (itemId?: number) => {
    setInviteItemId(itemId);
  };

  const onActive = (e: any, item: any) => {
    updateUserActivationStatus({
      id: item.id,
    });
    setUsers((prevUsers) => {
      const index = prevUsers.findIndex(({ id }: any) => id === item.id);
      prevUsers[index].isActive = !prevUsers[index].isActive;
      return [...prevUsers];
    });
  };

  const onReceiveReports = (e: any, item: any) => {
    updateUserReceiveReportsStatus({
      id: item.id,
    });
    setUsers((prevUsers) => {
      const index = prevUsers.findIndex(({ id }: any) => id === item.id);
      prevUsers[index].canReceiveReports = !prevUsers[index].canReceiveReports;
      return [...prevUsers];
    });
  };

  const onInviteConfirmed = async () => {
    await inviteUser({
      id: inviteItemId,
    });
  };

  const onDialogClose = () => {
    setDialogOpen(false);
    setEditItemId(undefined);
    setDeleteItemId(undefined);
    setInviteItemId(undefined);
  };

  const onDialogSuccess = async () => {
    onDialogClose();
    resetDelete();
    resetInvite();
    setTimeout(async () => {
      setIsLoadingUsers(true);
      await refetch();
    }, 100);
  };

  return (
    <LayoutStack>
      <MainTitle>Users List</MainTitle>
      <Toolbar
        onFilterChange={setFilters}
        onCreate={onCreate}
        onRefresh={() => {
          setIsLoadingUsers(true);
          refetch();
        }}
      />
      <ContentStack>
        {!isLoadingUsers && error && <ErrorState error={error} />}
        {isLoadingUsers && !error && <Loading />}
        {!isLoadingUsers && !error && users && (
          <Grid
            {...{
              rows: filteredUsers,
              columnsDef: getColumnsDef(
                ROW_HEIGHT,
                onEdit,
                onDelete,
                onInvite,
                onActive,
                onReceiveReports,
              ),
              onEdit,
              onDelete,
              onInvite,
              ROW_HEIGHT,
              maxVisibleRows,
              filters,
            }}
          />
        )}
        {(editItemId || dialogOpen) && (
          <UpsertUser
            item={users.find(({ id }: any) => id === editItemId)}
            onClose={onDialogClose}
            onSuccess={onDialogSuccess}
          />
        )}
        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete User"
            text="Are you sure you want to delete this user?"
            onOk={onDeleteConfirmed}
            onCancel={onDialogClose}
            onDeleteSuccess={onDialogSuccess}
            asyncApi={{
              loading: deleteLoading,
              error: deleteError,
              data: deleteData,
            }}
          />
        )}
        {!!inviteItemId && (
          <ConfirmDialog
            title="Send Invitation Email"
            text="Are you sure you want to invite this user?"
            onOk={onInviteConfirmed}
            onCancel={onDialogClose}
            onDeleteSuccess={onDialogSuccess}
            asyncApi={{
              loading: inviteLoading,
              error: inviteError,
              data: inviteData,
            }}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};
export default UserList;
