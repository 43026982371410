import { useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { Grid } from '../../../components/grid';
import { ContentStack, Loading } from '../../../components/styled';
import { useGetCargoType, useUpdateLiftCargoType } from '../../../queries';
import { useAppSelector } from '../../../store/hooks';
import { calculateDistance, formatDurationToHourMinSec } from '../../../utils';
import getColumnsDef from './columns';
dayjs.extend(utc);
dayjs.extend(timezone);

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const LiftList = ({
  dailyRecords,
  recordsIsLoading,
  refetchGetLifts,
  startDate,
}: any) => {
  let gridRef: any = null;
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const { data: cargoTypes, isLoading: cargoTypesIsLoading } =
    useGetCargoType();
  const { mutate: updateLiftCargoType } = useUpdateLiftCargoType({
    onSuccess: () => {
      refetchGetLifts();
    },
  });
  const timezone = useAppSelector((state: any) => state.projectCrane.Timezone);
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  // const selectedCrane = useAppSelector(
  //   (state: any) => state.projectCrane.Crane,
  // );
  // const user = storageHelper.getItem(USER);

  // const rows = useMemo(() => {
  //   const items = dailyRecords?.items?.map(({ item, ...rest }: any) => ({
  //     ...rest,
  //     ...item,
  //   }));
  //   const durations = compact(
  //     flatten(dailyRecords.records.map(({ durations }: any) => durations)),
  //   );
  //   return flatten(
  //     durations.map(({ task, ...rest }: any) => {
  //       const taskItems = items.filter(({ ID }: any) => ID === task.ID);
  //       const row = {
  //         ...rest,
  //         ...task,

  //         loadDuration: secondsToMinutes(
  //           sum(
  //             taskItems
  //               ?.filter(({ type }: any) => type === 'load')
  //               .map((item: any) => item.seconds),
  //           ),
  //           true,
  //         ),
  //         unloadDuration: secondsToMinutes(
  //           sum(
  //             taskItems
  //               ?.filter(({ type }: any) => type === 'unload')
  //               .map((item: any) => item.seconds),
  //           ),
  //           true,
  //         ),
  //         transitionWithLoadDuration: secondsToMinutes(
  //           sum(
  //             taskItems
  //               ?.filter(({ type }: any) => type === 'transitionWithLoad')
  //               .map((item: any) => item.seconds),
  //           ),
  //           true,
  //         ),
  //         transitionWithoutLoadDuration: secondsToMinutes(
  //           sum(
  //             taskItems
  //               ?.filter(({ type }: any) => type === 'transitionWithoutLoad')
  //               .map((item: any) => item.seconds),
  //           ),
  //           true,
  //         ),
  //       };
  //       return [row];
  //     }),
  //   );
  // }, [dailyRecords]);

  const rows: any = useMemo(() => {
    return lifts.map((lift: any) => {
      // const loadState = lift?.states?.find(
      //   (state: any) => state.type === LiftStateType.LOAD,
      // );
      // const unloadState = lift?.states?.find(
      //   (state: any) => state.type === LiftStateType.UNLOAD,
      // );
      // const transitionWithLoadState = lift?.states?.find(
      //   (state: any) => state.type === LiftStateType.TRANSITION_WITH_LOAD,
      // );
      // const transitionWithoutLoadState = lift?.states?.find(
      //   (state: any) => state.type === LiftStateType.TRANSITION_WITHOUT_LOAD,
      // );

      return {
        ...lift,
        distance: calculateDistance(lift),
        duration: formatDurationToHourMinSec(lift.durationSec),
      };
    });
  }, [lifts]);

  const onUpdateCargoType = (record: any, cargoTypeName: string) => {
    const cargoTypeId = cargoTypes?.data?.find(
      ({ name }: any) => name === cargoTypeName,
    )?.id;

    // dispatch(
    //   projectCraneActions.setLifts(
    //     lifts.map((lift: any) => {
    //       if (lift.id === record.id) {
    //         return {
    //           ...lift,
    //           cargoType: {
    //             id: cargoTypeId,
    //             name: cargoTypeName,
    //           },
    //         };
    //       }
    //       return lift;
    //     }),
    //   ),
    // );
    updateLiftCargoType({ id: record.id, cargoTypeId: cargoTypeId });
    // if (row) {
    //   const rowNode = gridRef.api.getRowNode(record.id);
    //   if (rowNode) {
    //     rowNode.setDataValue('CargoTypeId', cargoTypeId);

    //     const cargoType = cargoTypes?.data?.find(
    //       ({ id }: any) => id === cargoTypeId,
    //     );
    //     if (cargoType) {
    //       rowNode.setDataValue('CargoTypeColor', cargoType.color);
    //     }
    //   }
    // }
  };

  const onGridReady = (api: any) => {
    if (api) {
      gridRef = api;
    }
  };

  const isLoading = recordsIsLoading || cargoTypesIsLoading;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <ContentStack>
        {isLoading && <Loading />}
        {!isLoading && (
          <Grid
            {...{
              className: 'grid-small',
              options: {
                onGridReady,
                getRowNodeId: (data: any) => data.ID,
                rowSelection: 'multiple',
              },
              defaultColDef: {
                resizable: true,
              },
              rows,
              columnsDef: getColumnsDef(
                sm ? 200 : ROW_HEIGHT,
                cargoTypes?.data,
                onUpdateCargoType,
                rows,
                sm,
                medium,
              ),
              ROW_HEIGHT: sm ? 200 : ROW_HEIGHT,
              maxVisibleRows,
              hideHeader: sm,
              isLiftsGrid: true,
            }}
          />
        )}
      </ContentStack>
    </div>
  );
};

export default LiftList;
