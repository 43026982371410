import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Stack } from '@mui/material';
import { useEffect, useReducer } from 'react';
import { SearchInput } from '../../components/inputs';
import { GridToolbar } from '../../components/styled';

const reducer = (state: any, action: any) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

export interface ToolbarProps {
  onCreate: (create: boolean) => void;
  onRefresh: () => void;
  onFilterChange: (filters: any) => void;
  filters?: any;
}

export const Toolbar = ({
  onCreate,
  onRefresh,
  onFilterChange,
}: ToolbarProps) => {
  const [filters, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  return (
    <GridToolbar>
      <Stack direction="row" spacing={4}>
        <Button
          variant="contained"
          onClick={() => onCreate(true)}
          endIcon={<AddIcon />}
          sx={{ width: 120 }}
        >
          Add
        </Button>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <SearchInput
          placeholder="Search by user name, email or company"
          value={filters?.name}
          onChange={(e) => {
            dispatch({ type: 'query', payload: e.target.value });
          }}
          onClear={() => dispatch({ type: 'query', payload: '' })}
          minWidth={380}
        />
        <Button
          variant="outlined"
          onClick={() => onRefresh()}
          sx={{ height: 39 }}
        >
          <RefreshIcon />
        </Button>
      </Stack>
    </GridToolbar>
  );
};
