import { Stack, useMediaQuery } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import EmptyState from '../../../components/alerts/EmptyState';
import { useLongPress } from '../../../hooks/useLongPress';
import { useGetTimelineImages } from '../../../queries';
import { ImageDialog, Sensors, SliderNavigation } from './components';
import { settingsInitialState } from './components/Settings';
import { SlideContent } from './components/SlideContent';
import { Loading, StyledGrid, StyledSwiper } from './styled';

export const ImageTimeline = ({
  startDate,
  selectedSite,
  selectedCrane,
}: any) => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const timeout = useRef<any>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [settings, setSettings] = useState<any>(settingsInitialState);
  const sm = useMediaQuery('(max-width: 768px)');
  const [sensor, setSensor] = useState('Trolley');
  const [continuationToken, setContinuationToken] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState('');
  const [continuationTokens, setContinuationTokens] = useState<string[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  const { data, error, isLoading, status } = useGetTimelineImages(
    {
      startDate,
      selectedSite,
      selectedCrane,
      sensor,
      continuationToken,
    },
    {
      enabled: selectedSite !== '' && selectedCrane !== '',
    },
  );

  const onNextPage = (newContinuationToken: string) => {
    if (!newContinuationToken) return;

    setContinuationTokens((prevTokens) => [
      ...prevTokens,
      newContinuationToken,
    ]);
    setCurrentPageIndex((prevIndex) => prevIndex + 1);
    setContinuationToken(newContinuationToken);
    setActiveIndex(0);
    slideTo(0);
  };

  const onPreviousPage = () => {
    if (currentPageIndex <= 0) return;

    const newPageIndex = currentPageIndex - 1;
    setCurrentPageIndex(newPageIndex);

    const previousToken = continuationTokens[newPageIndex - 1] || null;
    setContinuationToken(previousToken); // Go back to previous token (null if first page)

    setActiveIndex(0);
    slideTo(0);
  };

  const slideTo = useCallback(
    (index: number) => {
      try {
        swiperRef?.slideTo(index);
      } catch (e) {}
    },
    [swiperRef],
  );

  const onSlideChange = ({ activeIndex }: any) => {
    setActiveIndex(activeIndex);
  };

  const getKeyDetails = (key: string) => {
    if (!key) {
      return '';
    }

    const segments = key.split('/');
    const data = segments[segments.length - 1].split('_');

    if (sensor === 'Trolley') {
      return `${data[0]}:${data[1]}:${data[2]
        .split('?')[0]
        .replace('.jpg', '')}`;
    }

    return `${data[6]}:${data[7]}:${data[8]}`;
  };

  // useEffect(() => {
  //   if (swiperRef) {
  //     setContinuationToken(null);
  //     setActiveIndex(0);
  //     slideTo(0);
  //   }
  // }, [sensor, startDate, selectedSite, selectedCrane, swiperRef, slideTo]);

  const onSettingsChange = (newSettings: any) => {
    setSettings(newSettings);
  };

  const onLongPressEnd = () => {
    if (timeout.current) {
      clearInterval(timeout.current);
      timeout.current = null;
    }
  };

  const onLongPressStart = (e: any, flag: boolean) => {
    if (!timeout.current) {
      let currentIndex = activeIndex;
      let i = 0;
      let delta = 1;
      timeout.current = setInterval(() => {
        if (currentIndex === 0) {
          onLongPressEnd();
        }
        currentIndex = flag ? currentIndex + delta : currentIndex - delta;
        slideTo(currentIndex);
        delta = Math.round(i / 5);
        i++;
      }, 150);
    }
  };

  const onClick = (flag: boolean) => {
    slideTo(flag ? activeIndex + 1 : activeIndex - 1);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const nextLongPressEvents = useLongPress(
    (e: any) => onLongPressStart(e, true),
    onLongPressEnd,
    () => onClick(true),
    defaultOptions,
  );

  const backLongPressEvents = useLongPress(
    (e: any) => onLongPressStart(e, false),
    onLongPressEnd,
    () => onClick(false),
    defaultOptions,
  );

  const handleClickOpen = (imageSrc: string) => {
    setImageSrc(imageSrc);
  };

  const handleClose = () => {
    setImageSrc('');
  };

  const imageStyle = sensor === '3D' ? { transform: 'rotate(270deg)' } : {};

  return (
    <StyledGrid
      container
      flex={1}
      alignContent="flex-start"
      sx={{ marginTop: 0, overflowY: 'visible', height: sm ? '100%' : '90%' }}
    >
      <ImageDialog {...{ imageSrc, handleClose, getKeyDetails, imageStyle }} />
      <Sensors {...{ setSensor, sensor, settings, onSettingsChange }} />
      {status === 'success' && data?.data?.Contents.length === 0 ? (
        <Stack height="100%" width="100%">
          <EmptyState />
        </Stack>
      ) : isLoading ? (
        <Loading />
      ) : (
        <>
          <StyledSwiper
            onSwiper={setSwiperRef}
            sx={{
              paddingBottom: 10,
              height: '100%',
            }}
            // @ts-ignore
            slidesPerView={sm ? 1 : sensor === '3D' ? 4 : 2}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            onSlideChange={onSlideChange}
            modules={[
              Virtual,
              Navigation,
              Pagination,
              Keyboard,
              Mousewheel,
              Scrollbar,
            ]}
            mousewheel
            freeMode
            centeredSlides
            navigation
            virtual
          >
            {data?.data?.Contents?.map(({ signedUrl }: any, index: number) => (
              <SwiperSlide
                key={signedUrl}
                virtualIndex={index}
                style={{ height: '100%' }}
              >
                <SlideContent
                  {...{
                    imageSrc: signedUrl,
                    getKeyDetails,
                    handleClickOpen,
                    imageStyle,
                  }}
                />
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <SliderNavigation
            {...{
              nextLongPressEvents,
              backLongPressEvents,
              slideTo,
              activeIndex,
              data,
              continuationToken,
              onPreviousPage,
              onNextPage,
              currentPageIndex,
              label: getKeyDetails(data?.data.Contents[activeIndex]?.signedUrl),
            }}
          />
        </>
      )}
    </StyledGrid>
  );
};
