import { Button, Stack, TextField, Typography, styled } from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ConfirmDialog } from '../../../../../components/confirm';
import { FormGroup } from '../../../../../components/inputs';
import { ContentStack, LayoutStack } from '../../../../../components/styled';
import { usePutDeleteProjectSitemap } from '../../../../../queries';
import { useAppSelector } from '../../../../../store/hooks';
import { convertXYToLatLong } from '../../../../../utils/coordinatesHelper';
import validate, { validators } from '../../../../../utils/validate';
import { ZonesDialog } from '../ProjectAntiCollision/components';
import ZonesMap, {
  ZonesMapMode,
} from '../ProjectAntiCollision/components/ZonesDialog/components/ZonesMap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { USER } from '../../../../../variables/constants';
import { UserRole } from '../../../../../variables/enums';
import * as storageHelper from '../../../../../utils/storageHelper';
dayjs.extend(utc);
dayjs.extend(timezone);

const reducer = (state: any, action: any) => {
  if (action.type === 'set_initial_state') return action.payload;
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const ResponsiveStack = styled(Stack)`
  width: 300px;

  @media screen and (min-width: 1280px) {
    width: 350px;
  }
  @media screen and (min-width: 1366px) {
    width: 400px;
  }
  @media screen and (min-width: 1600px) {
    width: 500px;
  }
  @media screen and (min-width: 1920px) {
    width: 600px;
  }
`;

const TextInputField = ({
  field,
  label,
  state,
  validationResults,
  dispatch,
  disabled = false,
}: any) => {
  return (
    <TextField
      fullWidth
      required
      disabled={disabled}
      type="number"
      InputLabelProps={{ shrink: true }}
      label={label}
      value={state[field] || ''}
      {...validationResults.results[field]}
      onChange={(e) =>
        dispatch({
          type: field,
          payload: e.target.value,
        })
      }
    />
  );
};

const SiteParameters = (props: any) => {
  const { siteData, siteMutate } = props;
  const user = storageHelper.getItem(USER);
  const isAdmin = user?.role === UserRole.ADMIN;

  const {
    id: siteId,
    name,
    company: company,
    originLatitude,
    originLongitude,
    prohibitedZones,
    cranes,
    users,
    timezone,
  } = siteData;

  const initialState = {
    originLatitude: originLatitude?.toString() ?? '',
    originLongitude: originLongitude?.toString() ?? '',
  };

  //#region react-query
  const queryClient = useQueryClient();

  const {
    mutate: putDeleteProjectSitemapMutate,
    isLoading: putDeleteProjectSitemapIsLoading,
    data: putDeleteProjectSitemapData,
    error: putDeleteProjectSitemapError,
    reset: putDeleteProjectSitemapReset,
  } = usePutDeleteProjectSitemap({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('PutDeleteProjectSitemap');
    },
  });

  //#endregion

  const [state, dispatch] = useReducer(reducer, initialState);

  const [deleteImageOpen, setDeleteImageOpen] = useState(false);
  const [siteCenter, setSiteCenter] = useState<any>({
    lat: 0,
    lng: 0,
  });
  const [openZonesDialog, setOpenZonesDialog] = useState(false);
  const zones = useAppSelector((state: any) => state.projectCrane.Zones);
  const [time, setTime] = useState('');

  useEffect(() => {
    if (!timezone) return;
    // Update the time once immediately before starting the interval
    setTime(dayjs().tz(timezone).format('HH:mm'));

    const interval = setInterval(() => {
      setTime(dayjs().tz(timezone).format('HH:mm'));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  // const {
  //   data: projectRes,
  //   error,
  //   isLoading,
  // } = useGetZones(
  //   { siteId },
  //   {
  //     enabled: !!siteId,
  //   },
  // );

  // Mapping the data
  // const mapZonePointsToZones = (data) => {
  //   const zonesMap = data.reduce((acc, { zoneid, xpoint, ypoint }) => {
  //     if (!acc[zoneid]) {
  //       acc[zoneid] = {
  //         name: '', // You might want to derive or assign names differently
  //         height: 0, // Adjust based on your requirements
  //         points: [],
  //       };
  //     }

  //     const points = convertXYToLatLong(siteCenter, xpoint, ypoint);
  //     acc[zoneid].points.push(points);

  //     return acc;
  //   }, {});

  //   // return Object.keys(zonesMap).map((key) => ({
  //   //   ...zonesMap[key],
  //   //   name: projectRes?.data
  //   //     ? projectRes.data.filter(
  //   //         (zone: any) => Number(zone.ID) === Number(key),
  //   //       )[0]?.ZoneName
  //   //     : '',
  //   //   active: projectRes?.data
  //   //     ? projectRes.data.filter(
  //   //         (zone: any) => Number(zone.ID) === Number(key),
  //   //       )[0]?.Active
  //   //     : true,
  //   // }));
  // };

  useEffect(() => {
    if (originLatitude && originLongitude) {
      setSiteCenter({
        lat: originLatitude,
        lng: originLongitude,
      });
    }
  }, [originLatitude, originLongitude]);

  useEffect(() => {
    // if (siteData.prohibitedZones && siteCenter && projectRes?.data) {
    //   const zonePoints = mapZonePointsToZones(siteData.prohibitedZones);
    //   setZones(zonePoints);
    // }
  }, [
    siteData.prohibitedZones,
    siteCenter,
    // projectRes
  ]);

  const onClose = () => {
    dispatch({
      type: 'set_initial_state',
      payload: initialState,
    });
  };

  const onDeleteImageConfirmed = () =>
    putDeleteProjectSitemapMutate({ siteId });

  const onDialogClose = () => {
    setDeleteImageOpen(false);
  };

  const onDialogSuccess = async () => {
    onDialogClose();
    putDeleteProjectSitemapReset();
    // setTimeout(async () => await refetchSite(), 100);
  };

  const onClickDeleteImage = () => {
    setDeleteImageOpen(true);
  };

  const onSubmit = async () => {
    if (!valid) return;
    await siteMutate({ siteId, ...state });
  };

  const isInitialState = isEqual(state, initialState);

  const validationResults = useMemo(() => {
    const definitions = {
      originLatitude: [
        () => validators.isRequired(state.originLatitude.toString()),
      ],
      originLongitude: [
        () => validators.isRequired(state.originLongitude.toString()),
      ],
    };
    return validate(state, definitions);
  }, [state]);

  const valid = validationResults.valid;

  const handleOpenZonesDialog = () => {
    setOpenZonesDialog(true);
  };

  const handleCloseZonesDialog = () => {
    setOpenZonesDialog(false);
  };

  return (
    <Stack width="100%" spacing={4}>
      <LayoutStack>
        <ContentStack>
          <Stack flex={1} width="100%">
            <Stack
              flex={1}
              spacing={8}
              direction="row"
              width="100%"
              sx={{ padding: 4 }}
            >
              <ResponsiveStack alignItems="flex-start" spacing={4} flex={1}>
                <FormGroup
                  title="Site prohibited zones"
                  sx={{ fontSize: '22px' }}
                >
                  {!openZonesDialog && (
                    <ZonesMap
                      {...{
                        siteCenter,
                        zones,
                        newZone: null,
                        setNewZone: null,
                        zonesMapMode: ZonesMapMode.view,
                      }}
                    />
                  )}
                  <Button
                    // sx={{ width: props.width }}
                    variant="contained"
                    onClick={handleOpenZonesDialog}
                  >
                    Edit zones
                  </Button>
                </FormGroup>
              </ResponsiveStack>
              <Stack flex={1} spacing={4} height={'300px'}>
                <Stack spacing={6}>
                  <FormGroup title="Site origin">
                    <Stack spacing={6}>
                      <TextInputField
                        field="originLatitude"
                        label="Latitude"
                        disabled={!isAdmin}
                        {...{
                          state,
                          validationResults,
                          dispatch,
                        }}
                      />
                      <TextInputField
                        field="originLongitude"
                        label="Longitude"
                        disabled={!isAdmin}
                        {...{
                          state,
                          validationResults,
                          dispatch,
                        }}
                      />
                    </Stack>
                  </FormGroup>
                  <FormGroup
                    title="Site details"
                    style={{ paddingTop: '20px' }}
                  >
                    <Stack
                      direction="column"
                      style={{ marginTop: '10px' }}
                      sx={{
                        padding: 2,
                        borderRadius: 2,
                        border: 'solid 1px',
                        borderColor: '#e9ebec',
                        flex: 1,
                        // maxWidth: 350,
                      }}
                      spacing={4}
                    >
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          // fontWeight: 'bold',
                          // textDecoration: 'underline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Name: <b>{name}</b>
                      </Typography>
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          // fontWeight: 'bold',
                          // textDecoration: 'underline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Company: <b>{company.name}</b>
                      </Typography>
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Time:{' '}
                        <b>
                          {time}{' '}
                          <AccessTimeIcon style={{ marginBottom: '2px' }} />
                        </b>
                      </Typography>
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          // fontWeight: 'bold',
                          // textDecoration: 'underline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Cranes: <b>{cranes.length}</b>
                      </Typography>
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Users: <b>{users.length}</b>
                      </Typography>
                      <Typography
                        color="#878a99"
                        sx={{
                          fontSize: 16,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Prohibited zones: <b>{prohibitedZones.length}</b>
                      </Typography>
                    </Stack>
                  </FormGroup>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Button onClick={onClose} disabled={isInitialState}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!valid || isInitialState}
                onClick={onSubmit}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </ContentStack>
        {deleteImageOpen && (
          <ConfirmDialog
            title="Delete project sitemap"
            text="Are you sure you want to delete this project sitemap?"
            onOk={onDeleteImageConfirmed}
            onCancel={onDialogClose}
            onDeleteSuccess={onDialogSuccess}
            asyncApi={{
              loading: putDeleteProjectSitemapIsLoading,
              error: putDeleteProjectSitemapError,
              data: putDeleteProjectSitemapData,
            }}
          />
        )}
        {openZonesDialog && (
          <ZonesDialog
            open={openZonesDialog}
            siteId={siteId}
            handleClose={handleCloseZonesDialog}
            projectName={siteData.name}
          />
        )}
      </LayoutStack>
    </Stack>
  );
};

export default SiteParameters;
