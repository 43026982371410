import React from 'react';
import { Checkbox } from '@mui/material';

const CheckboxRenderer = ({
  value,
  onChange,
  onClick,
  data,
  disabled = false,
}: any) => {
  if (value === undefined || value === null) return '';
  return (
    <Checkbox
      disabled={disabled}
      checked={value}
      onChange={onChange}
      onClick={(e) => onClick(e, data)}
    />
  );
};

export default CheckboxRenderer;
