import { DialogContent, Stack, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Tab, TabPanel } from '../../../Tabs';
import ImagesTimelineTab from '../LiftModalTabs/ImagesTimelineTab/ImagesTimelineTab';
import { InformationTab } from '../LiftModalTabs/InformationTab';
import { useGetLiftImages } from '../../../../queries';
import MapTabGoogleMaps from '../LiftModalTabs/MapTab/MapTabGoogleMaps';

const LiftModalContent = (props: any) => {
  const { data } = props;
  const { id } = data;

  const sm = useMediaQuery('(max-width: 680px)');
  const [selectedTab, setSelectedTab] = React.useState(0);

  const {
    isLoading: getLiftImagesIsLoading,
    data: imagesData,
    isSuccess: getLiftImagesIsSuccess,
    error: getLiftImagesError,
    refetch: refetchLiftImages,
    isFetching: getLiftImagesIsFetching,
  } = useGetLiftImages({ id });

  useEffect(() => {
    if (id) {
      refetchLiftImages();
    }
  }, [id]);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <DialogContent
      sx={{
        padding: 0,
        minHeight: 400,
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      <Stack
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          height: '600px',
          overflow: 'visible',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          sx={{ paddingLeft: 4 }}
        >
          <Tab label="Information" />
          <Tab label="Map" />
          <Tab label="Images" />
          {/* <Tab label="Position" /> */}
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <InformationTab data={data} images={imagesData?.data?.images} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <MapTabGoogleMaps data={data} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <ImagesTimelineTab
            lift={data}
            images={imagesData?.data?.images}
            isLoading={getLiftImagesIsLoading || getLiftImagesIsFetching}
          />
        </TabPanel>
      </Stack>
    </DialogContent>
  );
};

export default LiftModalContent;
