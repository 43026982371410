import { useAppDispatch } from './store/hooks';
import { mediaResActions } from './store/mediares';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TotalWorkDayHours } from './components/theme';
import { flatten } from 'lodash';
import { LiftStateType, UserRole } from './variables/enums';
import { getDistance } from 'geolib';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type Point = { x: number; y: number };

export const calcDist = (pointA: Point, pointB: Point) => {
  return Math.sqrt(
    Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2),
  );
};
export const calcSlope = (pointA: Point, pointB: Point) => {
  return (pointA.y - pointB.y) / (pointA.x - pointB.x);
};

export const calcC1Point = (pointB: Point, perp: number) => {
  const pointA = { x: 0, y: 0 };
  const slope = calcSlope(pointA, pointB);
  if (slope === 0) {
    return { x: 0, y: perp };
  }

  const m = -1 / slope;
  const x = perp / Math.sqrt(Math.pow(m, 2) + 1);
  const y = m * x;
  return { x, y };
};

export const calcC2Point = (pointB: Point, perp: number) => {
  const c1Point = calcC1Point(pointB, perp);
  const diffX = pointB.x;
  const diffY = pointB.y;
  return { x: c1Point.x + diffX, y: c1Point.y + diffY };
};

export const calcCubicPoints = (pointB: Point, middlePoint: Point) => {
  const pointA = { x: 0, y: 0 };
  const m = calcSlope(pointA, pointB);

  const perp = Math.abs(
    (m * middlePoint.x - middlePoint.y) / Math.sqrt(m * m + 1),
  );

  return [calcC1Point(pointB, perp), calcC2Point(pointB, perp)];
};

export const SetMediaRes = () => {
  const dispatch = useAppDispatch();

  let orientationLandscape = false;
  let medialevel = 0;
  if (window.matchMedia('(orientation: Landscape)').matches) {
    orientationLandscape = true;
  }
  if (window.matchMedia('(min-width: 700px)').matches && orientationLandscape) {
    medialevel = 2;
  } //smartphone Rotated
  if (
    window.matchMedia('(min-width: 800px)').matches &&
    !orientationLandscape
  ) {
    medialevel = 3;
  } //tablet
  if (
    window.matchMedia('(min-width: 1280px)').matches &&
    orientationLandscape
  ) {
    medialevel = 4;
  } //screen
  if (medialevel === 0) {
    medialevel = 1;
  }
  // console.log('medialevel='+medialevel);
  // if (medialevel!==mediaResLevel)
  // {

  dispatch(mediaResActions.setMediaRes({ level: medialevel }));

  // console.log('mediaResLevel='+mediaResLevel);

  // }
};

/**
 * Format the duration between two dates in the format "HH:MM:SS"
 * @param start - start date as a dayjs object
 * @param end - end date as a dayjs object
 * @returns - A string representing the duration between the two dates in the format "HH:MM:SS"
 */
export const formatDurationBetweenDates = (
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
): string => {
  // Calculate the duration between the two dates in milliseconds
  const diff = end.diff(start);

  // Use dayjs duration to handle the duration
  const diffDuration = dayjs.duration(diff);

  // Format hours, minutes, and seconds
  const hours = diffDuration.hours().toString().padStart(2, '0');
  const minutes = diffDuration.minutes().toString().padStart(2, '0');
  const seconds = diffDuration.seconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Calculate the duration between two dates in seconds
 * @param start - start date
 * @param end - end date
 * @returns - The duration between the two dates in seconds
 */
export const calculateDurationBetweenDates = (
  start: string,
  end: string,
  as: 'seconds' | 'minutes' | 'hours' | 'days',
): number => {
  // Calculate the duration between the two dates in milliseconds
  const diff = dayjs(end).diff(dayjs(start));

  // Use dayjs duration to handle the duration
  const diffDuration = dayjs.duration(diff);

  // Convert the duration to seconds
  if (as === 'minutes') return diffDuration.asMinutes();
  if (as === 'hours') return diffDuration.asHours();
  if (as === 'days') return diffDuration.asDays();
  return diffDuration.asSeconds();
};

/**
 * Format the total seconds to the format "HH:MM:SS"
 * @param totalSeconds - total seconds
 * @returns - A string representing the total seconds in the format "HH:MM:SS"
 */
export const formatTotalSecondsToDateFormat = (
  totalSeconds: number,
  withHours = true,
  withAmPm = false,
): string => {
  let showHours = false;
  if (totalSeconds > 3600) {
    showHours = true;
  }
  // Use dayjs duration to handle the duration
  const diffDuration = dayjs.duration(totalSeconds, 'seconds');

  // Format hours, minutes, and seconds
  const hours = diffDuration.hours().toString().padStart(2, '0');
  const minutes = diffDuration.minutes().toString().padStart(2, '0');
  const seconds = diffDuration.seconds().toString().padStart(2, '0');

  if (withAmPm) {
    const ampm = diffDuration.hours() >= 12 ? 'PM' : 'AM';
    return withHours || showHours
      ? `${hours}:${minutes}:${seconds} ${ampm}`
      : `${minutes}:${seconds} ${ampm}`;
  }

  return withHours || showHours
    ? `${hours}:${minutes}:${seconds}`
    : `${minutes}:${seconds}`;
};

/**
 * Format the total seconds into "Xh Ym Zs" or "Ym Zs" if under an hour
 * @param totalSeconds - The total duration in seconds
 * @returns A formatted string like "1h 25m 30s" or "25m 30s"
 */
export const formatDurationToHourMinSec = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  return `${minutes}m ${seconds}s`;
};


export const displayUserRole = (role: UserRole): string => {
  switch (role) {
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.COMPANY_EXECUTIVE:
      return 'Company Executive';
    case UserRole.SITE_TEAM_MEMBER:
      return 'Site Team Member';
    case UserRole.CRANE_GROUND_TEAM_MEMBER:
      return 'Crane Ground Team Member';
    default:
      return '';
  }
};

/**
 * Calculate the distance in meters between two geographic coordinates
 * @param lift - The lift object containing source and destination coordinates
 * @returns The distance in meters
 */
export const calculateDistance = (lift:any): number => {
  const startPosition = {
    latitude: lift.sourceLatitude,
    longitude: lift.sourceLongitude,
  };
  const endPosition = {
    latitude: lift.destinationLatitude,
    longitude: lift.destinationLongitude,
  };

  return getDistance(startPosition, endPosition);
};

