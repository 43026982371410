import dayjs from 'dayjs';
import { ActionsRenderer, CheckboxRenderer } from '../../components/grid';
import { displayUserRole } from '../../utils';

export default (
  ROW_HEIGHT: string | number,
  onEdit?: any,
  onDelete?: any,
  onInvite?: any,
  onActive?: any,
  onReceiveReports?: any,
) => {
  return [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
      minWidth: 320,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      sort: 'asc',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      minWidth: 300,
      flex: 1,
    },
    {
      headerName: 'Company',
      field: 'company.name',
      sortable: true,
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: 'Role',
      field: 'role',
      minWidth: 220,
      sortable: true,
      cellRendererFramework: (params: any) => {
        return displayUserRole(params.value);
      },
    },
    // {
    //   headerName: 'Receive Reports',
    //   field: 'canReceiveReports',
    //   sortable: true,
    //   cellRendererFramework: CheckboxRenderer,
    //   minWidth: 60,
    //   cellRendererParams: {
    //     onClick: onReceiveReports,
    //     disabled: true,
    //   },
    // },
    {
      headerName: 'Active',
      field: 'isActive',
      sortable: true,
      cellRendererFramework: CheckboxRenderer,
      minWidth: 100,
      cellRendererParams: {
        onClick: onActive,
        disabled: true,
      },
    },
    {
      headerName: 'AC Enabler',
      field: 'isAntiCollisionEnabler',
      sortable: true,
      cellRendererFramework: CheckboxRenderer,
      minWidth: 100,
      cellRendererParams: {
        onClick: onActive,
        disabled: true,
      },
    },
    {
      headerName: 'Last Invitation Date',
      field: 'lastInvitationDate',
      cellRendererFramework: (params: any) => {
        return params.value
          ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
          : '';
      },
      sortable: true,
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: 'Last Password Change Date',
      field: 'lastPasswordChangeDate',
      cellRendererFramework: (params: any) => {
        return params.value
          ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
          : '';
      },
      sortable: true,
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'id',
      minWidth: 140,
      maxWidth: 140,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onEdit,
        // onDelete,
        onInvite,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
