import { Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import * as storageHelper from '../../../../../utils/storageHelper';
import {
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../../../../variables/constants';
import SiteGoogleMaps, {
  GoogleMapPoint,
  SiteGoogleMapsMode,
  SiteGoogleMapsType,
} from '../../../../SiteGoogleMaps/SiteGoogleMaps';

const MapTabGoogleMaps = ({ data: lift }: any) => {
  const {
    sourceLatitude,
    sourceLongitude,
    destinationLatitude,
    destinationLongitude,
  } = lift;

  const user = storageHelper.getItem(USER);
  const selectedSite = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);

  const [source, setSource] = useState<GoogleMapPoint | null>(null);
  const [destination, setDestination] = useState<GoogleMapPoint | null>(null);
  const [siteCenter, setSiteCenter] = useState<GoogleMapPoint>({
    lat: 0,
    lng: 0,
  });

  const [destinationIcon, setDestinationIcon] = useState<any>(null);
  const [sourceIcon, setSourceIcon] = useState<any>(null);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    if (isGoogleMapsLoaded && window.google) {
      setDestinationIcon({
        url: require('../../../../../assets/icons/destination.svg').default,
        scaledSize: new window.google.maps.Size(40, 40), // Ensure window.google is available
      });

      setSourceIcon({
        url: require('../../../../../assets/icons/source.svg').default,
        scaledSize: new window.google.maps.Size(40, 40),
      });
    }
  }, [isGoogleMapsLoaded]);

  // MapBox
  useEffect(() => {
    if (!sourceLatitude || !sourceLongitude) {
      if (source?.lat || source?.lng) setSource(null);
      return;
    } else {
      setSource({ lat: Number(sourceLatitude), lng: Number(sourceLongitude) });
    }

    if (!destinationLatitude || !destinationLongitude) {
      if (destination?.lat || destination?.lng) setDestination(null);
      return;
    } else {
      setDestination({
        lat: Number(destinationLatitude),
        lng: Number(destinationLongitude),
      });
    }
  }, [lift]);

  useEffect(() => {
    if (user && selectedSite) {
      const site = user.sites.find((site: any) => site.id === selectedSite);
      if (!site) return;
      setSiteCenter({
        lat: site.originLatitude,
        lng: site.originLongitude,
      });
    }
  }, [storageHelper]);

  return (
    <div style={{ height: '100%' }}>
      {siteCenter && (
        <SiteGoogleMaps
          siteCenter={siteCenter}
          mode={SiteGoogleMapsMode.view}
          mapType={SiteGoogleMapsType.satellite}
          onLoad={() => setIsGoogleMapsLoaded(true)}
        >
          <>
            {source && sourceIcon && (
              <Marker key={source?.lat} position={source} icon={sourceIcon} />
            )}
            {destination && destinationIcon && (
              <Marker
                key={destination?.lat}
                position={destination}
                icon={destinationIcon}
              />
            )}
          </>
        </SiteGoogleMaps>
      )}
    </div>
  );
};

export default MapTabGoogleMaps;
