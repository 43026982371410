import { Stack, Typography } from '@mui/material';
import LiftModal from '../../../components/LiftModal/LiftModal';
import { SelectRowRenderer } from './components';

export default (
  ROW_HEIGHT: string | number,
  cargoTypes: any[],
  onUpdateCargoType: (record: any, cargoTypeName: string) => void,
  newRows: any[],
  isMobile?: boolean,
  sm: boolean = false,
  medium: boolean = false,
) => {
  const COLUMN_WIDTH = sm ? 95 : medium ? 95 : 250;

  return [
    {
      headerName: 'Date Created',
      field: 'DateCreated',
      sortable: false,
      cellRendererFramework: ({ data }: any) => {
        const liftIndex = newRows.findIndex((row) => row.id === data.id);
        return (
          <Stack
            spacing={2}
            width="100%"
            height={ROW_HEIGHT}
            sx={{ padding: 4 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h2Medium"> Lift {liftIndex + 1}</Typography>
              <LiftModal liftIndex={liftIndex} />
            </Stack>
            <Stack spacing={2} style={{ width: '100%' }}>
              {/* <Typography variant="smallRegular">
                Preload Move: <b>{data.preloadStartTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                Load Start: <b>{data.loadStartTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                Load End: <b>{data.loadEndTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                Unload Start: <b>{data.unloadStartTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                Unload End: <b>{data.unloadEndTime}</b>
              </Typography> */}
              <Typography variant="smallRegular">
                Start time: <b>{data.startTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                End time: <b>{data.endTime}</b>
              </Typography>
              <Typography variant="smallRegular">
                Duration: <b>{data.duration} minutes</b>
              </Typography>
              <Typography variant="smallRegular">
                Distance: <b>{data.distance} meters</b>
              </Typography>
              <Typography variant="smallRegular">
                Weight: <b>{Number(data.weight * 0.001).toFixed(2)} tons</b>
              </Typography>
              <Typography variant="smallRegular">
                Cargo type: <b>{data.cargoType.name}</b>
              </Typography>
            </Stack>
          </Stack>
        );
      },
      hide: !isMobile,
    },
    {
      headerName: '',
      field: 'split',
      sortable: true,
      width: 50,
      minWidth: sm || medium ? 50 : 100,
      maxWidth: sm || medium ? 50 : 100,
      hide: isMobile,
      cellRendererFramework: (data: any) => {
        const liftNum = newRows.findIndex((row) => row.id === data.data.id) + 1;
        return (
          <>
            <Typography>{liftNum || '-'}</Typography>
          </>
        );
      },
    },
    {
      headerName: 'Start',
      field: 'startTime',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
    },
    {
      headerName: 'End',
      field: 'endTime',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
    },
    {
      headerName: 'Duration (Minutes)',
      field: 'duration',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
    },
    {
      headerName: `Distance ${sm || medium ? '(M)' : '(Meters)'}`,
      field: 'distance',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
    },
    {
      headerName: `Weight ${sm || medium ? '(T)' : '(Tons)'}`,
      field: 'weight',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
      cellRendererFramework: ({ value }: any) => {
        return Number(value * 0.001).toFixed(2);
      },
    },
    {
      headerName: 'Type',
      field: 'cargoType.name',
      sortable: false,
      hide: isMobile,
      minWidth: COLUMN_WIDTH + 60,
      maxWidth: COLUMN_WIDTH + 60,
      cellRendererFramework: SelectRowRenderer,
      cellRendererParams: {
        selectOptions: cargoTypes,
        onUpdateCargoType,
      },
    },
    {
      headerName: '',
      field: 'id',
      minWidth: 100,
      maxWidth: 100,
      hide: isMobile,
      cellRendererFramework: (data: any) => {
        const liftIndex = newRows.findIndex((row) => row.id === data.data.id);
        return <LiftModal liftIndex={liftIndex} />;
      },
    },
  ];
};
