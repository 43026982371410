import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  TextField,
  CircularProgress,
  MenuItem,
  Checkbox,
  // FormGroup,
  FormControlLabel,
  Divider,
  Typography,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { sortBy } from 'lodash';
// import PasswordStrengthBar from 'react-password-strength-bar';
import { ErrorAlert } from '../../components/alerts';
import { useGetCompanies, usePutUser, usePostUser } from '../../queries';
// import { PasswordInput } from '../../components/inputs';
import validate, { validators } from '../../utils/validate';
import { USER } from '../../variables/constants';
import * as storageHelper from '../../utils/storageHelper';
import Clear from '@mui/icons-material/Clear';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyId: '',
  role: '',
  isActive: true,
  canReceiveReports: false,
  isAntiCollisionEnabler: false,
  isAdmin: false,
  selectedSites: [],
};

const reducer = (state: any, action: any) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const UpsertUser = ({ onClose, onSuccess, item }: any) => {
  const [state, dispatch] = useReducer(reducer, item || {});
  const [sites, setSites] = useState<any>([]);
  const user = storageHelper.getItem(USER);

  useEffect(() => {
    if (!user || !user?.sites) return;

    setSites(user.sites);
  }, [storageHelper]);

  const [userRoles, setUserRoles] = React.useState<any[]>([
    {
      id: 0,
      label: 'Admin',
      value: 'ADMIN',
    },
    {
      id: 1,
      label: 'Company Executive',
      value: 'COMPANY_EXECUTIVE',
    },
    {
      id: 2,
      label: 'Site Team Member',
      value: 'SITE_TEAM_MEMBER',
    },
    {
      id: 3,
      label: 'Crane Ground Team Member',
      value: 'CRANE_GROUND_TEAM_MEMBER',
    },
  ]);

  const isCreateForm = !item;
  const passwordWasTouched = state.Password ? state.Password !== '' : false;

  const onChangeScore = (score: number, feedback: any) => {
    dispatch({ type: 'PasswordScore', payload: { score, feedback } });
  };

  const validationResults = useMemo(() => {
    const definitions = {
      firstName: [() => validators.isRequired(state.firstName)],
      lastName: [() => validators.isRequired(state.lastName)],
      email: [
        () => validators.isRequired(state.email),
        () => validators.isEmail(state.email),
      ],
      // password: [
      //   () =>
      //     (isCreateForm || passwordWasTouched) &&
      //     validators.isRequired(state.password),
      //   () =>
      //     (isCreateForm || passwordWasTouched) &&
      //     validators.hasStrength(state?.password?.score, 2),
      // ],
      companyId: [
        () => validators.hasLength(state.companyId, 'Company Name is required'),
      ],
      sites: [() => validators.hasLength(state.sites, 'Sites is required')],
      // confirmPassword: [
      //   () =>
      //     (isCreateForm || passwordWasTouched) &&
      //     validators.isRequired(state.confirmPassword),
      //   () =>
      //     (isCreateForm || passwordWasTouched) &&
      //     validators.isEqual(
      //       state.password,
      //       state.confirmPassword,
      //       'Password and Confirm Password do not match',
      //     ),
      // ],
    };
    return validate(state, definitions);
  }, [state, item]);

  const valid = validationResults.valid;

  const {
    mutateAsync: updateUser,
    data: usePutUserData,
    error: usePutUserError,
    isLoading: usePutUserIsLoading,
  } = usePutUser();
  const {
    mutateAsync: createUser,
    data: usePostUserData,
    error: usePostUserError,
    isLoading: usePostUserIsLoading,
  } = usePostUser();
  const { data: companies } = useGetCompanies();

  const data = usePutUserData || usePostUserData;
  const error = usePutUserError || usePostUserError;
  const isLoading = usePutUserIsLoading || usePostUserIsLoading;

  const onSubmit = async () => {
    if (!valid) return;
    if (isCreateForm) {
      const { PasswordScore, ...restState } = state;
      await createUser(restState);
    } else {
      const {
        password,
        tempPassword,
        tempPasswordExperationDate,
        ...restState
      } = state;
      await updateUser(restState);
    }
  };

  useEffect(() => {
    if (data) {
      onSuccess();
    }
  }, [data, onSuccess]);

  useEffect(() => {
    if (companies?.data && isCreateForm) {
      dispatch({ type: 'CompanyId', payload: companies.data[0].ID });
    }
  }, [companies, item]);

  if (!companies?.data) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>{isCreateForm ? 'Create' : 'Update'} User</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={8} width="100%">
          <Stack direction="row" spacing={8} width="100%">
            <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              label="First Name"
              value={state.firstName || ''}
              autoFocus
              {...validationResults.results['firstName']}
              onChange={(e) =>
                dispatch({ type: 'firstName', payload: e.target.value })
              }
            />
            <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              label="Last Name"
              value={state.lastName || ''}
              autoFocus
              {...validationResults.results['lastName']}
              onChange={(e) =>
                dispatch({ type: 'lastName', payload: e.target.value })
              }
            />
          </Stack>
          <Stack direction="row" spacing={8} width="100%">
            <TextField
              label="Email"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: !isCreateForm,
              }}
              value={state.email || ''}
              {...validationResults.results['email']}
              disabled={!isCreateForm}
              onChange={(e) =>
                dispatch({ type: 'email', payload: e.target.value })
              }
            />
          </Stack>
          <Stack direction="row" spacing={8} width="100%" alignItems="center">
            <Stack flex={1}>
              {companies?.data && companies?.data?.length > 0 && (
                <TextField
                  select
                  label="Company"
                  InputLabelProps={{ shrink: true }}
                  value={state.companyId}
                  fullWidth
                  required
                  {...validationResults.results['companyId']}
                  onChange={(e) => {
                    dispatch({ type: 'companyId', payload: e.target.value });
                  }}
                >
                  {companies.data.length > 0 &&
                    sortBy(companies.data, 'name').map((company: any) => (
                      <MenuItem key={uuid()} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Stack>
            <Stack flex={1}>
              <TextField
                select
                label="Role"
                InputLabelProps={{ shrink: true }}
                value={state.role}
                fullWidth
                required
                {...validationResults.results['role']}
                onChange={(e) => {
                  dispatch({ type: 'role', payload: e.target.value });
                }}
              >
                {userRoles.length > 0 &&
                  sortBy(userRoles, 'id').map((role: any) => (
                    <MenuItem key={uuid()} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={8} width="100%" alignItems="center">
            <FormControl fullWidth required>
              <InputLabel id="sites-label" shrink>
                Sites
              </InputLabel>
              <Select
                labelId="sites-label"
                multiple
                value={state?.sites || []}
                onChange={(e) =>
                  dispatch({ type: 'sites', payload: e.target.value })
                }
                input={
                  <OutlinedInput
                    label="Sites"
                    notched
                    endAdornment={
                      item?.sites.length > 0 && (
                        <InputAdornment
                          position="end"
                          sx={{ position: 'absolute', right: 32 }}
                        >
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              dispatch({ type: 'sites', payload: [] });
                            }}
                            edge="end"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                }
                renderValue={(selected: any) =>
                  sites
                    .filter((site: any) => selected.includes(site.id))
                    .map((site: any) => site.name)
                    .join(', ')
                }
              >
                {sites?.length > 0 &&
                  sites.map((site: any) => (
                    <MenuItem key={site.id} value={site.id}>
                      <Checkbox
                        checked={
                          state?.sites ? state?.sites.includes(site.id) : false
                        }
                        sx={{
                          color: '#888',
                          '&.Mui-checked': {
                            color: '#1976d2',
                          },
                        }}
                      />
                      <Typography>{site.name}</Typography>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={8}
            width="100%"
            alignItems="flex-start"
          >
            {/* {isCreateForm && (
              <Stack flex={1} spacing={6}>
                <Typography sx={{ color: 'primary.main' }}>
                  Set Password
                </Typography>
                <Stack spacing={2} width="100%">
                  <PasswordInput
                    fullWidth
                    label="Passoword"
                    value={state.password || ''}
                    required={isCreateForm}
                    {...validationResults.results['password']}
                    onChange={(e) =>
                      dispatch({ type: 'password', payload: e.target.value })
                    }
                  />
                  <PasswordStrengthBar
                    password={state.password}
                    onChangeScore={onChangeScore}
                  />
                  <PasswordInput
                    label="Confirm Password"
                    fullWidth
                    value={state.confirmPassword || ''}
                    required={isCreateForm}
                    {...validationResults.results['confirmPassword']}
                    onChange={(e) =>
                      dispatch({
                        type: 'confirmPassword',
                        payload: e.target.value,
                      })
                    }
                  />
                </Stack>
              </Stack>
            )} */}

            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack flex={1} spacing={2}>
              <Stack flex={1} direction="column">
                <FormControlLabel
                  style={{ display: isCreateForm ? 'none' : 'block' }}
                  control={
                    <Checkbox
                      checked={state.isActive}
                      onChange={(e) => {
                        dispatch({
                          type: 'isActive',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isAntiCollisionEnabler}
                      onChange={(e) => {
                        dispatch({
                          type: 'isAntiCollisionEnabler',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Anti Collision Enabler"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.canReceiveReports}
                      onChange={(e) => {
                        dispatch({
                          type: 'canReceiveReports',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Receive Reports"
                />
              </Stack>
              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isAdmin}
                      onChange={(e) => {
                        dispatch({
                          type: 'isAdmin',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="isAdmin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.CompanyExecutiveMember}
                      onChange={(e) => {
                        dispatch({
                          type: 'CompanyExecutiveMember',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Company Executive Member"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.Stockkeeper}
                      onChange={(e) => {
                        dispatch({
                          type: 'Stockkeeper',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Stockkeeper"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.Signaller}
                      onChange={(e) => {
                        dispatch({
                          type: 'Signaller',
                          payload: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Signaller"
                />
              </FormGroup> */}
            </Stack>
          </Stack>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions sx={{ padding: 4 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!valid || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={16} />
          ) : isCreateForm ? (
            'Create'
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertUser;
